{
  "c": "BETA",
  "calendar": {
    "addAppointment": "Přidat schůzku",
    "downloadChecklist": "Kontrolní seznam",
    "filter": {
      "warnings": {
        "noExperienceMatchingFilter": "Žádné odpovídající zážitky",
        "noExperiences": "Žádné odpovídající zážitky"
      }
    },
    "warnings": {
      "noExperiences": "Žádné odpovídající zážitky"
    }
  },
  "coppi": {
    "navigation": {
      "bottom": {
        "quickActions": {
          "label": "Rychlé akce"
        }
      },
      "navAccount": {
        "help": {
          "label": "Nápověda"
        },
        "switch": {
          "label": "Přepnout na"
        }
      }
    }
  },
  "dateAndTime": {
    "days": "den | dny/dní",
    "hours": "hodina | hodin/y",
    "minutes": "min"
  },
  "errors": {
    "errorPages": {
      "notAuthorised": {
        "cta": "Přejít na přehled",
        "description": "Váš účet nemá práva potřebná k zobrazení této stránky.",
        "title": "Nemáte oprávnění zobrazit tuto stránku"
      },
      "serverError": {
        "cta": "Zkusit znovu",
        "description": "Z důvodu interní chyby serveru se nám nepodařilo dokončit váš požadavek. Byli jsme o tom informováni. Zkuste to prosím později znovu.",
        "title": "Ups, objevila se divoká chyba"
      },
      "walletManagement": {
        "noWalletErrorMessage": "Prodejce nemá aktivované žádné peněženky."
      }
    },
    "validationErrors": {
      "invalidIban": "Neplatný IBAN",
      "notAValidEmail": "Pole není platný e-mail",
      "notAValidMoneyAmount": "Neplatná částka",
      "notEmpty": "Pole nemůže být prázdné",
      "notIdenticalIban": "IBAN pro potvrzení musí být stejný jako IBAN",
      "unrecognisedDateTime": "Datum a čas nebyly rozpoznány"
    }
  },
  "modals": {
    "selectExperienceToAddDate": {
      "description": "Zvolte, ke kterému zážitku chcete přiřadit novou dostupnost.",
      "title": "Přidat k zážitku schůzku."
    }
  },
  "notifications": {
    "applicationUpdateAvailable": {
      "cta": "Aktualizovat",
      "title": "K dispozici je nová verze bookingkit"
    }
  },
  "pagination": {
    "next": "Další",
    "previous": "Předchozí"
  },
  "powerShortcuts": {
    "addAppointmentForExperience": "Přidat schůzku",
    "disableHere": "Zde můžete zakázat klávesové zkratky",
    "enableShortcuts": "Povolit klávesové zkratky",
    "keyboardShortcuts": "Klávesové zkratky",
    "ok": "OK",
    "powerShortcuts": "Zkratky",
    "search": "Hledat",
    "searchShortcutToastInfoMac": "Opětovným stisknutím ⌘ + F aktivujete nativní vyhledávání v prohlížeči.",
    "searchShortcutToastInfoWindows": "Opětovným stisknutím Ctrl + F aktivujete nativní vyhledávání v prohlížeči.",
    "toastMessage": "Klávesové zkratky jsou ve výchozím nastavení povoleny.",
    "vendorShortcutToastInfoMac": "Opětovným stisknutím ⌘ + M aktivujete nativní klávesové zkratky v prohlížeči.",
    "vendorShortcutToastInfoWindows": "Opětovným stisknutím Ctrl + M aktivujete nativní klávesové zkratky v prohlížeči.",
    "vendorSwitch": "Změna dodavatele"
  },
  "search": {
    "accepted": "PŘIJATO",
    "archived": "ARCHIVOVÁNO",
    "canceled": "ZRUŠENO",
    "coupon": "Kupón",
    "declined": "ZAMÍTNUTO",
    "expired": "VYPRŠELO",
    "failure": "CHYBA",
    "feature": "Vlastnost",
    "flexibleTicket": "Flexibilní vstupenka",
    "invalid": "Neplatné",
    "noResultFound": "Nebyl nalezen žádný výsledek.",
    "noResultFoundMessage": "Vyhledávání neodpovídá žádnému výsledku. Zkuste to jinak.",
    "open": "OTEVŘENO",
    "order": "Objednávka",
    "pending": "ZPRACOVÁVÁ SE",
    "reserved": "REZERVOVÁNO",
    "search": "Hledat",
    "searchEntryMessage": "Můžete vyhledávat:",
    "searchError": "Něco se pokazilo.",
    "searchErrorMessage": "Pracujeme na odstranění problému. Zkuste to brzy znovu.",
    "searchFeatureEntryMessage": "Vlastnosti podle názvů.",
    "searchOrderEntryMessage": "objednávky podle ID, údaje o zákazníkovi nebo jiné údaje související s objednávkou;",
    "searchScopes": {
      "all": "Vše",
      "coupons": "Kupóny",
      "features": "Vlastnosti",
      "flexibleTickets": "Flexibilní vstupenky",
      "orders": "Objednávky",
      "vouchers": "Poukazy"
    },
    "searchVoucherEntryMesaage": "Poukazy podle kódu, názvu nebo popisu;",
    "searchVoucherEntryMessage": "poukazy podle kódu, názvu nebo popisu;",
    "ticketTypes": {
      "all": "Vše",
      "coupons": "Kupóny",
      "features": "Vlastnosti",
      "flexibleTickets": "Flexibilní vstupenky",
      "orders": "Objednávky",
      "vouchers": "Poukazy"
    },
    "valid": "Platné",
    "voucher": "Poukaz"
  },
  "sidenav": {
    "account": {
      "companyData": {
        "label": "Údaje o společnosti"
      },
      "contract": {
        "label": "Smlouva"
      },
      "main": {
        "label": "účet"
      },
      "profile": {
        "label": "Profil"
      },
      "signOut": {
        "label": "Odhlásit se"
      },
      "userManagement": {
        "label": "Správa uživatelů"
      },
      "vendorSwitcher": {
        "label": "přepínač dodavatele"
      }
    },
    "admin": {
      "apiPayments": {
        "label": "Platby API"
      },
      "applicaitonLimits": {
        "label": "Limity použití"
      },
      "application": {
        "label": "Aplikace"
      },
      "applicationLimits": {
        "label": "Limity použití"
      },
      "changeRates": {
        "label": "Změnit ceny"
      },
      "chargeVouchers": {
        "label": "Dobíjecí poukazy"
      },
      "claimAccount": {
        "label": "Přiřadit účet"
      },
      "createAccount": {
        "label": "Vytvořit účet"
      },
      "dashboard": {
        "label": "Řídicího panel"
      },
      "disableEventDates": {
        "label": "Vypnout data událostí"
      },
      "emailConfiguration": {
        "label": "Konfigurace e-mailu"
      },
      "importCoupons": {
        "label": "Importovat kupóny"
      },
      "importOrders": {
        "label": "Importovat objednávky"
      },
      "importVouchers": {
        "label": "Importovat poukazy"
      },
      "inviteNew": {
        "label": "Pozvání nového prodejce"
      },
      "invoices": {
        "label": "Faktury"
      },
      "KYCUnverified": {
        "label": "KYC neověřeno"
      },
      "main": {
        "label": "Admin"
      },
      "manageAPI": {
        "label": "Správa API"
      },
      "manageBkUsers": {
        "label": "Správa uživatelů bk"
      },
      "mangopayDisputes": {
        "label": "Spory Mangopay"
      },
      "marketingPartners": {
        "label": "Marketingoví partneři"
      },
      "outstandingPayments": {
        "label": "Neuhrazené platby"
      },
      "paymentRequests": {
        "label": "Žádosti o platbu"
      },
      "payoutChannels": {
        "label": "Výplatní kanály"
      },
      "paypalPaymentRequests": {
        "label": "Žádosti o platbu PayPal"
      },
      "platforms": {
        "label": "Platformy"
      },
      "provider": {
        "label": "Poskytovatel"
      },
      "rates": {
        "label": "Ceny"
      },
      "resellerPaymentStatus": {
        "label": "Stav platby prodejce"
      },
      "setupFee": {
        "label": "Poplatek za nastavení"
      },
      "translationTool": {
        "label": "Překladatelský nástroj"
      },
      "unknownPaymentrequests": {
        "label": "Neznámé žádosti o platbu"
      }
    },
    "beta": "BETA",
    "calendar": {
      "day": {
        "label": "Zobrazení dne"
      },
      "feed": {
        "label": "Kanál kalendáře"
      },
      "main": {
        "label": "Kalendář"
      },
      "month": {
        "label": "Zobrazení měsíce"
      }
    },
    "inventory": {
      "bundles": {
        "label": "Svazky"
      },
      "experiences": {
        "label": "Zážitky"
      },
      "flexibleOffers": {
        "label": "Flexibilní nabídky"
      },
      "main": {
        "label": "Inventář"
      },
      "products": {
        "label": "Produkty"
      },
      "resources": {
        "label": "Zdroje"
      }
    },
    "marketplace": {
      "main": {
        "label": "Přidat marketplace"
      }
    },
    "orders": {
      "flexibleTickets": {
        "label": "Flexibilní vstupenky"
      },
      "main": {
        "label": "Objednávky"
      },
      "orders": {
        "label": "Objednávky"
      },
      "participants": {
        "label": "Účastníci"
      },
      "promotionalCoupons": {
        "label": "Propagační kupóny"
      },
      "requests": {
        "label": "Požadavky"
      },
      "vouchers": {
        "label": "Poukazy"
      }
    },
    "overview": {
      "main": {
        "label": "Přehled"
      }
    },
    "reach": {
      "affiliateLinks": {
        "label": "Partnerské odkazy"
      },
      "googleThingsToDo": {
        "label": "Google Things to do"
      },
      "inviteNew": {
        "label": "Pozvání nového prodejce"
      },
      "main": {
        "label": "Reach"
      },
      "marketplace": {
        "label": "Marketplace"
      },
      "myPartners": {
        "label": "Moji partneři"
      },
      "partnerRates": {
        "label": "Partnerské sazby"
      },
      "platforms": {
        "label": "Platformy"
      },
      "promote": {
        "label": "Propagujte místní prostředí"
      },
      "reachOrders": {
        "label": "Objednávky"
      }
    },
    "reports": {
      "aggregateStatistics": {
        "label": "Souhrnné statistiky"
      },
      "exports": {
        "label": "Exporty"
      },
      "main": {
        "label": "Zprávy"
      },
      "monthlyStatement": {
        "label": "Měsíční výpis"
      },
      "statements": {
        "label": "Prohlášení"
      },
      "statistics": {
        "label": "Statistiky"
      }
    },
    "resellers": {
      "inviteNew": {
        "label": "Reach"
      },
      "main": {
        "label": "Prodejci"
      },
      "marketplace": {
        "label": "Marketplace"
      },
      "myPartners": {
        "label": "Moji partneři"
      },
      "orders": {
        "label": "Objednávky"
      },
      "platforms": {
        "label": "Integrace prodejců"
      },
      "promote": {
        "label": "Propagujte místní zážitky"
      }
    },
    "sell": {
      "checkoutConfiguration": {
        "label": "Konfigurace pokladny"
      },
      "emailCampaigns": {
        "label": "E-mailové kampaně"
      },
      "localExperiences": {
        "label": "Místní zážitky"
      },
      "main": {
        "label": "Prodej"
      },
      "paymentOptions": {
        "label": "Možnosti platby"
      },
      "paymentProviders": {
        "label": "Poskytovatelé plateb"
      },
      "widgets": {
        "label": "Widgety"
      }
    },
    "settings": {
      "calendarFeed": {
        "label": "Kanál kalendáře"
      },
      "emailConfiguration": {
        "label": "Konfigurace e-mailu"
      },
      "invoiceLayout": {
        "label": "Rozložení faktury"
      },
      "invoiceNNumber": {
        "label": "Číslo faktury"
      },
      "invoiceNumber": {
        "label": "Číslo faktury"
      },
      "main": {
        "label": "Nastavení"
      },
      "modules": {
        "label": "Moduly"
      },
      "ticketConfiguration": {
        "label": "Konfigurace vstupenek"
      },
      "translations": {
        "label": "Překlady"
      },
      "vat": {
        "label": "DPH"
      },
      "voucherConfiguration": {
        "label": "Konfigurace poukazů"
      }
    },
    "shortcuts": {
      "appMarketplace": {
        "label": "Přidat marketplace"
      },
      "appointment": {
        "label": "Přidat schůzku"
      },
      "booking": {
        "label": "Rezervace"
      },
      "bookingKit": {
        "label": "bookingkit"
      },
      "bookingKitClassic": {
        "label": "bookingkit classic"
      },
      "bookingKitReach": {
        "label": "bookingkit Reach"
      },
      "contactSupport": {
        "label": "Kontaktovat podporu"
      },
      "copyToClipboard": {
        "failure": "Nepodařilo se zkopírovat odkaz pro dodavatele",
        "success": "Konkrétní odkaz zkopírován pro dodavatele:"
      },
      "date": {
        "label": "Datum"
      },
      "experience": {
        "label": "Zážitek"
      },
      "knowledgeBase": {
        "label": "Databáze informací",
        "url": "https://help.bookingkit.com/"
      },
      "onboardingWizard": {
        "label": "Průvodce zaváděním (bude upřesněno)"
      },
      "powerShortcuts": {
        "label": "Zkratky"
      }
    },
    "updates": {
      "main": {
        "label": "Aktualizace produktu"
      },
      "mainBadge": {
        "label": "Nwe"
      }
    },
    "wallet": {
      "main": {
        "label": "Peněženka"
      },
      "partnerPayments": {
        "label": "Partnerské platby"
      },
      "verification": {
        "label": "Ověření"
      },
      "walletManagement": {
        "label": "Správa peněženky"
      }
    }
  },
  "supplierSearch": {
    "availableVendorsLabel": "Dostupní dodavatelé",
    "dismiss": "Zrušit",
    "navigate": "Procházet",
    "noResult": "Nebyl nalezen žádný výsledek.",
    "recentUsed": "Naposledy použité",
    "resultsLabel": "Nalezení dodavetelé",
    "searchError": "Něco se pokazilo.",
    "searchErrorMessage": "Pracujeme na odstranění problému. Zkuste to brzy znovu.",
    "searchForVendor": "Vyhledat dodavatele",
    "select": "Vybrat",
    "storeResultsLabel": "Dostupní dodavatelé",
    "supplierAlreadyInUseError": "Aktuálně používáte tento účet dodavatele",
    "switchVendor": "Změnit dodavatele",
    "wishmorning": "Dobré ráno"
  }
}